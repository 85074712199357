import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { fetchValidations, findMaxCharLength, handleLength } from "../../../utils/helperFns";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";
import MaxLengthValidationMessage from "../Error/MaxLengthValidationMessage";

const TextBox = ({
  field,
  errors,
  isDisabled,
  register,
  clearErrors,
  handleChange,
  handleOnBlur,
  data,
  type,
}: any) => {
  const [restOfChar, setRestOfChar] = useState({
    lengthOfState: 0,
    restOfLength: 0,
    reachLimit: false,
  });

  const validations = fetchValidations(field?.validations);

  const handleKeyDown = (event) => {
    if (type === "number") {
      if (event.key === "e" || event.key === "E" || event.key === "-" || event.key === "+") {
        event.preventDefault();
      }
    }
  };

  return (
    <Box>
      <TextField
        variant='filled'
        size='small'
        type={type}
        name={field?.name}
        className='titlefield'
        placeholder={field?.placeholder}
        id={field?.name}
        error={errors?.[field?.name]}
        disabled={isDisabled}
        value={data}
        defaultValue={data}
        sx={{
          width: "100%",
          input: { cursor: isDisabled && "not-allowed" },
        }}
        inputProps={{
          readOnly: false,
          maxLength: findMaxCharLength(field?.validations),
        }}
        {...register(field?.name, {
          ...validations,
          onChange: (event) => {
            if (errors?.[field?.name]) {
              clearErrors(field?.name);
            }
            const { target: { value = "" } = {} } = event;
            handleLength(value, field?.validations, setRestOfChar, restOfChar);
            if (handleChange) {
              handleChange(event);
            }
          },
          onBlur: (event) => {
            handleOnBlur(event);
          },
        })}
        onKeyDown={handleKeyDown}
      />
      <ErrorMessageHandle error={errors[field?.name]} />
      {type !== "number" && (
        <MaxLengthValidationMessage
          validations={field?.validations}
          error={errors[field?.name]}
          restOfChar={restOfChar}
        />
      )}
    </Box>
  );
};
export default TextBox;
