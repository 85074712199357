import { handleLogout } from "@platformx/utilities";
import axios, { AxiosError, AxiosInstance } from "axios";
import { ApiError } from "../utils/types";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.NX_PUBLIC_API_URI,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
  timeout: 0, // Disable timeout to keep the connection open
});

axiosInstance.interceptors.request.use((config) => {
  // Customize the request if needed, e.g., for keepAlive-like behavior
  config.headers["Connection"] = "keep-alive";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("logout...from axios");
      handleLogout();
      return Promise.reject("Unauthorized");
    }
    // Handle other errors here
    return Promise.reject(error);
  },
);

export const createAxiosError = (err: AxiosError): ApiError => {
  return { message: err.message, status: err.response?.status ?? 500 };
};
export default axiosInstance;
