import { Box } from "@mui/material";
import MagicUrl from "quill-magic-url";
import "quill-paste-smart";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import { handleLength } from "../../utils/helperFns";
import "./Editor.css";
import ErrorMessageHandle from "./ErrorMessageHandle";
import MaxLengthValidationMessage from "./MaxLengthValidationMessage";

Quill.register("modules/magicUrl", MagicUrl);
type EditorDescriptionProps = {
  optionEditor?: any;
  description: string;
  errors: any;
  field: any;
  handleChange: any;
  control: any;
  state: any;
};

const Block = Quill.import("blots/block");
class MyBlock extends Block {
  static create() {
    const node = super.create();
    node.setAttribute("style", "margin: 0 0 10px 0;");
    return node;
  }
}
Quill.register(MyBlock);

const EditorDescription = (props: EditorDescriptionProps) => {
  const {
    description = "",
    optionEditor = [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
    ],
    errors,
    field,
    handleChange,
    control,
    state,
  } = props;

  const [value, setValue] = useState<string>("");
  const [restOfChar, setRestOfChar] = useState({
    lengthOfState: 0,
    restOfLength: 0,
    reachLimit: false,
  });
  const reactQuillRef = useRef<ReactQuill>(null);

  const handleOnChange = (e: string) => {
    const val = e
      .replace(/<[^>]*>/g, " ")
      .replace(/\s{2,}/g, " ")
      .trim();
    setValue(e);
    if (val.length > 0) {
      const event = { target: { name: field?.name, value: e } };
      handleChange(event);
    } else {
      const event = { target: { name: field?.name, value: "" } };
      handleChange(event);
    }
    handleLength(val, field?.validations, setRestOfChar, restOfChar);
  };

  const isHTML = (str: any) => {
    const elem = document.createElement("p");
    elem.innerHTML = str;
    return elem.children.length > 0;
  };

  useEffect(() => {
    if (description) {
      if (isHTML(description)) {
        setValue(description);
      } else {
        setValue(`<p>${description}</p>`);
      }
    }
  }, [description]);

  const handleValidate = () => {
    const currentValue = state[field.name];
    const required = field?.validations?.find((x) => x.type === "required");
    if (required && !currentValue) {
      return required?.message;
    }
  };

  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current && reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor && unprivilegedEditor?.getLength() > 1000 && event.key !== "Backspace")
      event.preventDefault();
  };

  return (
    <Box id={`${field?.name}_id`}>
      <Controller
        name={field.name}
        control={control}
        rules={{ validate: handleValidate }}
        render={({ field: controllerField }) => (
          <ReactQuill
            id={field?.name}
            modules={{
              toolbar: optionEditor,
              clipboard: {
                matchVisual: false,
              },
              magicUrl: true,
            }}
            theme='snow'
            value={value}
            preserveWhitespace
            ref={reactQuillRef}
            bounds={`#${controllerField?.name}_editor`}
            onChange={(v) => {
              handleOnChange(v);
            }}
            onKeyDown={checkCharacterCount}
          />
        )}
      />
      <ErrorMessageHandle error={errors[field.name]} />
      <MaxLengthValidationMessage
        validations={field?.validations}
        error={errors[field?.name]}
        restOfChar={restOfChar}
      />
    </Box>
  );
};
export default React.memo(EditorDescription);
