/* eslint-disable template-tag-spacing */
import { gql } from "@apollo/client";

export const userGroupsQueries = {
  GET_USER_GROUPS_LIST: gql`
    query GET_USER_GROUPS_LIST($pagination: community_Paginate!) {
      community_getUserGroupList(pagination: $pagination) {
        id
        children
        label
        description
        tags
        group_name
        parent_id
        name
        status
        created_at
        updated_at
      }
    }
  `,
  GET_USER_GROUP_DETAILS: gql`
    query GET_USER_GROUP_DETAILS($id: String!) {
      community_getUserGroupDetails(id: $id)
    }
  `,
  GET_TAGS_LIST: gql`
    query GET_TAGS_LIST($search: String, $pagination: community_Paginate!) {
      community_getTagsList(pagination: $pagination, search: $search)
    }
  `,
};
