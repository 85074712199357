import graphqlInstance from "../../config/graphqlConfig";
import { cugQueries } from "../../graphQL/queries/cugQueries";
import { ApiResponse } from "../../utils/types";

const cugApi = {
  getCommunityUserGroups: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: cugQueries.GET_CUG_LIST,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      return err;
    }
  },
};

export default cugApi;
