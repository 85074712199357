import AddIcon from "@mui/icons-material/Add";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Box, Fab, IconButton, useTheme } from "@mui/material";

import {
  CrossIcon,
  ImageContentIcon,
  PlusNewIcon,
  VideoNewIcon,
} from "@platformx/shared/static-assets";
import { XToolTip } from "@platformx/utilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./MediaTray.styles";

export default function MediaTray({ showGallery }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showTray, setShowTray] = useState(false);
  const classes = useStyles();
  const openGallery = (type) => {
    setShowTray(false);
    showGallery(type);
  };
  return (
    <Box
      sx={{
        display: "flex",
        zIndex: 999,
        opacity: 1,
        //minWidth: '200px'
      }}>
      <IconButton
        color='primary'
        aria-label='upload picture'
        component='label'
        sx={{
          padding: 0,
          alignSelf: "flex-end",
          display: { xs: "none", sm: "flex" },
        }}
        data-testid='mediatray'
        onClick={() => setShowTray(!showTray)}>
        {showTray ? (
          // <CancelOutlinedIcon sx={{ fontSize: "36px" }} />
          <img src={CrossIcon} alt='cross' />
        ) : (
          // <AddCircleOutlineIcon sx={{ color: "#14142B", fontSize: "36px" }} />
          <img src={PlusNewIcon} alt='plus' />
        )}
      </IconButton>
      <Fab
        sx={{
          position: "fixed",
          bottom: "4%",
          right: "5%",
          zIndex: 99,
          display: { xs: "flex", sm: "none" },
          backgroundColor: "#14142B",
        }}
        size='medium'
        color='primary'
        aria-label='add'
        onClick={() => setShowTray(!showTray)}>
        {showTray ? (
          <ClearRoundedIcon sx={{ color: "#FFFFFF" }} />
        ) : (
          <AddIcon sx={{ color: "#FFFFFF" }} />
        )}
      </Fab>
      {showTray ? (
        <Box
          sx={{
            zIndex: { xs: 99, sm: 1 },
            display: { xs: "flex" },
            flexDirection: { xs: "column", sm: "unset" },
          }}
          className={classes.plusIconBox}>
          <XToolTip
            Title={t("image")}
            position='bottom'
            component={
              <IconButton
                className={classes.iconBoxCta}
                sx={{
                  padding: { xs: "5px", em: "15px" },
                  borderRadius: 0,
                  "&:hover": { background: theme.palette.customColor.INPUT },
                }}
                color='primary'
                aria-label='upload picture'
                component='label'
                onClick={() => openGallery("Images")}
                data-testid='mediatray-open-gallery'>
                <img src={ImageContentIcon} alt='article' />
              </IconButton>
            }
          />
          <XToolTip
            Title={t("video")}
            position='bottom'
            component={
              <IconButton
                className={classes.iconBoxCta}
                sx={{
                  padding: { xs: "5px", em: "19px" },
                  borderRadius: 0,
                  "&:hover": { background: theme.palette.customColor.INPUT },
                }}
                color='primary'
                aria-label='upload video'
                component='label'
                onClick={() => openGallery("Videos")}
                data-testid='mediatray-open-videos'>
                <img src={VideoNewIcon} alt='video' />
              </IconButton>
            }
          />
          {/* <XToolTip
            Title={t("article")}
            position='bottom'
            component={
              <IconButton
                className={classes.iconBoxCta}
                sx={{
                  padding: { xs: "5px", em: "17px" },
                  borderRadius: 0,
                  "&:hover": { background: theme.palette.customColor.INPUT },
                }}
                color='primary'
                aria-label='upload content'
                component='label'
                onClick={() => openGallery("Article")}
                data-testid='mediatray-open-article'>
                <img src={ArticleIcon} alt='article' />
              </IconButton>
            }
          /> */}
          {/* <XToolTip
            Title={t("quiz")}
            position='bottom'
            component={
              <IconButton
                className={classes.iconBoxCta}
                sx={{
                  padding: { xs: "5px", em: "15px" },
                  borderRadius: 0,
                  "&:hover": { background: theme.palette.customColor.INPUT },
                }}
                color='primary'
                aria-label='upload content'
                component='label'
                onClick={() => openGallery("Quiz")}
                data-testid='mediatray-open-quiz'>
                <img src={QuizNewIcon} alt='quiz' />
              </IconButton>
            }
          /> */}
          {/* <XToolTip
            Title={t("poll")}
            position='bottom'
            component={
              <IconButton
                className={classes.iconBoxCta}
                sx={{
                  padding: { xs: "5px", em: "15px" },
                  borderRadius: 0,
                  "&:hover": { background: theme.palette.customColor.INPUT },
                }}
                color='primary'
                aria-label='upload content'
                component='label'
                onClick={() => openGallery("Poll")}
                data-testid='mediatray-open-poll'>
                <img src={Polls} alt='polls' />
              </IconButton>
            }
          /> */}
        </Box>
      ) : null}
    </Box>
  );
}
