import { gql } from "@apollo/client";
import { contentTypeSchemaApi, graphqlInstance } from "@platformx/authoring-apis";
import { convertToLowerCase, ShowToastError } from "@platformx/utilities";

export const findMaxCharLength = (validations) => {
  let length = 1000;
  if (validations.length > 0) {
    const findData = validations.find((x) => x.type === "maxLength");
    if (findData) {
      length = findData.value;
    }
  }
  return length;
};

export const handleLength = (valueData, validations, setRestOfChar, restOfChar) => {
  const maxCharLength = findMaxCharLength(validations);
  if (maxCharLength) {
    const lengthOfChar = convertToLowerCase(valueData).length;
    const rest = valueData ? maxCharLength - lengthOfChar : 0;
    setRestOfChar({
      ...restOfChar,
      restOfLength: rest < 0 ? 0 : rest,
      lengthOfState: lengthOfChar,
      reachLimit: maxCharLength <= lengthOfChar ? true : false,
    });
  }
};

export const createChannel = (contentInstance, event_id, host_id) => {
  const newContent = {
    title: contentInstance?.title,
    event_id: event_id,
    host_id: host_id,
    host_email: contentInstance?.hostemail,
  };
  return contentTypeSchemaApi.createChannel({
    input: newContent,
  });
};

export const fetchValidations = (validations) => {
  if (validations?.length > 0) {
    return validations.reduce((acc, obj) => {
      if (obj.type === "uirequired" || obj.type === "required") {
        return {
          ...acc,
          required: {
            value: true,
            message: obj.message,
          },
        };
      } else {
        return {
          ...acc,
          [obj.type]: {
            value: obj.type === "pattern" ? new RegExp(obj.value) : obj.value,
            message: obj.message,
          },
        };
      }
    }, {});
  }
  return {};
};

const valueCheck = (value) => {
  if (typeof value === "undefined") {
    return true;
  } else if (typeof value === "string") {
    return value === "";
  } else if (typeof value === "boolean") {
    return !value;
  } else if (typeof value === "object") {
    return Object.keys(value).length === 0;
  } else if (Array.isArray(value)) {
    return value.length === 0;
  }
};

export const fetchRequiredMessage = (validations, data) => {
  const required = validations.find((x) => x.type === "required" || x.type === "uirequired");
  return required && valueCheck(data) ? required?.message : "";
};

export const calculateInitialValuesWithData = (initialData, data) => {
  const initialValues: any = {};
  const dataObj = Object.keys(initialData);
  dataObj.forEach((field) => {
    initialValues[field] = data[field];
  });
  return initialValues;
};

export const calculateInitialValues = (fields) => {
  const initialValues: any = {};
  if (fields?.length > 0) {
    fields.forEach((field) => {
      initialValues[field.name] = field?.value || field?.type === "boolean" ? false : "";
    });
  }
  return initialValues;
};

export const createContentType = (contentInstance, contentType, schema_document_type) => {
  const newContent = { ...contentInstance, name: contentInstance?.title };
  return contentTypeSchemaApi.saveContent({
    contentType: contentType,
    input: newContent,
    schema_document_type: schema_document_type || "contentType",
  });
};

export const updateContentType = (
  savedData,
  contentInstance,
  contentType,
  schema_document_type,
) => {
  const { name } = savedData.current;
  const newContent = { ...contentInstance, name: name };
  return contentTypeSchemaApi.updateContent({
    contentType: contentType,
    input: newContent,
    schema_document_type: schema_document_type || "contentType",
  });
};

export const publishContentType = (savedData, contentType, schema_document_type) => {
  const { id, name } = savedData.current;
  const requestToSend = {
    document_name: name,
    document_id: id,
    status: "publish",
  };
  return contentTypeSchemaApi.publishContentType({
    contentType: contentType,
    input: requestToSend,
    schema_document_type: schema_document_type || "contentType",
  });
};

export const fetchSchema = (contentType) => {
  return contentTypeSchemaApi.getSchemaDynamic({
    filter: contentType,
  });
};

export const fetchSchemaWithData = (contentType, documentId, schema_document_type) => {
  return contentTypeSchemaApi.getSchemaDynamicWithData({
    contentType: contentType,
    name: documentId,
    schema_document_type: schema_document_type || "contentType",
  });
};

export const dateAndTimeValidator = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (start <= end && end >= start) {
    return true;
  } else {
    return false;
  }
};

export const dynamicAPICall = async (query, responseVariable) => {
  try {
    // Ensure the query is a valid DocumentNode
    const documentNode =
      typeof query === "string"
        ? gql`
            ${query}
          `
        : query;
    const { data = {} } = await graphqlInstance.query({
      query: documentNode,
      variables: {},
      fetchPolicy: "no-cache",
    });
    return data[responseVariable] || [];
  } catch (err: any) {
    ShowToastError("Failed to load dropdown values || " + err);
  }
};
