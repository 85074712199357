import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete, TextField, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import { cugApi } from "@platformx/authoring-apis";
import * as React from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' sx={{ color: "#14142B" }} />;
const checkedIcon = <CheckBoxIcon fontSize='small' sx={{ color: "#14142B" }} />;

interface ContentSelectProps {
  error: boolean;
  handleChange: (event) => void;
  state: [];
  handleOnBlur?: (event) => void;
  errorText?: string;
}

const CugDropDown = ({
  error,
  handleChange,
  state,
  handleOnBlur,
  errorText = "",
}: ContentSelectProps) => {
  const theme = useTheme();

  const [optionsList, setOptionsList] = React.useState([]);
  const [tempOptionsList, setTempOptionsList] = React.useState([]);
  const [value, setValue] = React.useState([]);

  const handleSelect = (_: any, val) => {
    const ifAllOption = val?.filter((item) => item?.id === "All");
    if (ifAllOption?.length) {
      setOptionsList(ifAllOption);
    } else {
      optionsList?.length === 1 && setOptionsList(tempOptionsList);
    }
    const event = { target: { name: "cug_id", value: ifAllOption?.length ? ifAllOption : val } };
    handleOnBlur && handleOnBlur(event);
    handleChange(event);
  };

  const getInitialData = async () => {
    const data: any = await cugApi.getCommunityUserGroups({
      search: "",
      pagination: { start: 0, rows: 1000 },
    });
    if (data?.community_getCugList?.length) {
      setOptionsList(data.community_getCugList);
      setTempOptionsList(data.community_getCugList);
    } else {
      setOptionsList([]);
      setTempOptionsList([]);
    }
  };

  React.useEffect(() => {
    getInitialData();
  }, []);

  React.useEffect(() => {
    setValue(state);
  }, [state]);

  return (
    <Box mr={2}>
      <FormControl fullWidth error={error}>
        <Autocomplete
          loading={optionsList?.length === 0}
          value={value}
          multiple={true}
          disableCloseOnSelect={true}
          popupIcon={<ExpandMoreIcon />}
          isOptionEqualToValue={(option: any, val: any) => {
            return option?.label === val?.label;
          }}
          options={optionsList}
          onChange={handleSelect}
          renderOption={(props, option: any, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                disabled={selected}
              />
              {option?.label}
            </li>
          )}
          renderInput={(params) => {
            return (
              <TextField
                variant='filled'
                style={{
                  border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
                  background: error ? "#FFEBEE" : "transparent",
                  borderRadius: "5px",
                }}
                {...params}
                placeholder={!value?.length ? "select CUG" : ""}
              />
            );
          }}
          classes={{
            popper: "autocompleteMultiSelectPopper",
            paper: "autocompleteMultiSelectPapper",
            inputRoot: "autocompleteMultiSelectInputRoot",
            tag: "autocompleteMultiSelectTag",
          }}
        />
        {errorText && (
          <Typography variant='p4regular' sx={{ marginTop: "10px", color: "#B71C1C" }}>
            {errorText}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};

export default CugDropDown;
