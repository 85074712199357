import { ShowToastError, ShowToastSuccess } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import userGroupsApi from "../../services/userGroups/userGroups.api";
import { userGroupsResponse } from "./useUserGropus.types";

export function useUserGroups() {
  const { t } = useTranslation();

  const getUserGroupList = async (props): Promise<userGroupsResponse> => {
    try {
      const response: any = await userGroupsApi.getUserGroupsList({
        pagination: props,
      });
      if (response?.community_getUserGroupList?.length >= 0) {
        return {
          data: response?.community_getUserGroupList,
        };
      } else {
        ShowToastError(t("api_error_toast"));
        return {
          data: [],
        };
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("INTERNAL SERVER ERROR", statusCode);
      return {
        data: [],
      };
    }
  };

  const createUserGroup = async (props): Promise<userGroupsResponse> => {
    try {
      const response: any = await userGroupsApi.createUserGroups({
        input: props,
      });
      if (response?.data?.community_createUserGroup?.message === "Created Successfully!!!") {
        return {
          success: true,
        };
      } else if (response?.error?.message === "Already Exist") {
        ShowToastError(t("group_already_exist"));
        return {
          success: false,
        };
      } else {
        ShowToastError(t("api_error_toast"));
        return {
          success: false,
        };
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("INTERNAL SERVER ERROR", statusCode);
      return {
        success: false,
      };
    }
  };

  const updateUserGroup = async (props): Promise<userGroupsResponse> => {
    try {
      const response: any = await userGroupsApi.updateUserGroups({
        input: props,
      });
      if (response?.data?.community_updateUserGroup?.message === "Successfully updated!!!") {
        ShowToastSuccess(t("group_update_success"));
        return {
          success: true,
        };
      } else {
        ShowToastError(t("api_error_toast"));
        return {
          success: false,
        };
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("INTERNAL SERVER ERROR", statusCode);
      return {
        success: false,
      };
    }
  };

  const deleteUserGroup = async (props): Promise<userGroupsResponse> => {
    try {
      const response: any = await userGroupsApi.deleteUserGroup(props);
      if (response?.community_deleteUserGroup?.message === "Deleted Successfully!!!") {
        ShowToastSuccess(t("group_delete_success"));
        return {
          success: true,
        };
      } else {
        ShowToastError(t("api_error_toast"));
        return {
          success: false,
        };
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("INTERNAL SERVER ERROR", statusCode);
      return {
        success: false,
      };
    }
  };

  const getUserGroupDetails = async (props): Promise<userGroupsResponse> => {
    try {
      const response: any = await userGroupsApi.getUserGroupDetails(props);
      if (Object.keys(response?.community_getUserGroupDetails)?.length > 0) {
        return {
          data: response?.community_getUserGroupDetails,
        };
      } else {
        ShowToastError(t("api_error_toast"));
        return {
          data: {},
        };
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("INTERNAL SERVER ERROR", statusCode);
      return {
        data: {},
      };
    }
  };

  const getUserGroupTagList = async (props): Promise<userGroupsResponse> => {
    try {
      const response: any = await userGroupsApi.getUserGroupTagList(props);
      if (response?.community_getTagsList?.length > 0) {
        return {
          data: response?.community_getTagsList,
        };
      } else {
        ShowToastError(t("api_error_toast"));
        return {
          data: [],
        };
      }
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("INTERNAL SERVER ERROR", statusCode);
      return {
        data: {},
      };
    }
  };

  return {
    createUserGroup,
    updateUserGroup,
    getUserGroupDetails,
    deleteUserGroup,
    getUserGroupList,
    getUserGroupTagList,
  };
}
