/* eslint-disable @typescript-eslint/no-unused-vars */
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import LinkIcon from "@mui/icons-material/Link";
import { Box, ButtonGroup, Dialog, IconButton, Tooltip } from "@mui/material";
import { createChatGptRequest } from "@platformx/authoring-apis";
import { ContentGallery } from "@platformx/schema-based-content-type";
import { AiSvg, AiSvgDisabled } from "@platformx/shared/static-assets";
import {
  CROP_TYPE,
  handleHtmlTags,
  Loader,
  PROJECT_CATCH_ERROR_LIST,
  relativeImageURL,
  trimString,
} from "@platformx/utilities";
import { DamContentGallery, usePostImageCrop } from "@platformx/x-image-render";
import DOMPurify from "isomorphic-dompurify";
import React, { useEffect, useRef, useState } from "react";
import ReactDomServer from "react-dom/server";
import DescriptionContentCard from "../DescriptionContentCard";
import MediaTray from "../MediaTray/MediaTray";
import AddUrlDialog from "../url-dialog-box/AddUrlDialog";
import ChatGptLoader from "./ChatGptLoader";
import "./editor.css";

function Description({
  title,
  showMediaOption,
  setShowMediaOption,
  state,
  setState,
  setCheckDesc,
}) {
  const currentLine = useRef<any>("");
  const galleryType = useRef<string>("Images");
  const contentTypes: string[] = ["Quiz", "Poll", "Article", "Vod"];
  const contentType = useRef<string[]>();
  const desc = useRef<any>("");
  const [galleryState, setGalleryState] = useState<boolean>(false);
  const { cropRequest } = usePostImageCrop();
  const [, setShowOutput] = useState<any>(null);
  const [, setSelectedImage] = useState({
    Thumbnail: "",
    Title: "",
    Description: "",
  });
  const [, setSelectedVideo] = useState({
    Thumbnail: "",
    Title: "",
    Description: "",
    Url: "",
  });
  const [, setData] = useState("");
  const [showToolbar, setShowToolbar] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [isUrlDialog, setUrlDialog] = useState(false);
  const [description, setDescription] = useState<any>("");
  const updateTempObj = useRef<any>({});
  const [contentGalleryState, setContentGalleryState] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ifLink] = useState(false);
  const [loader, setLoader] = useState(false);

  const handlePlaceholder = (e) => {
    const ele = document.getElementById("desc");
    // Get the placeholder attribute
    const placeholder = ele?.getAttribute("data-placeholder");
    // Set the placeholder as initial content if it's empty
    if (ele?.innerHTML === "") {
      ele.innerHTML = placeholder || "";
    }
    const value = e.target.innerHTML;
    if (value === placeholder) {
      e.target.innerHTML = "";
    }
  };

  const handleTextSelection = (e) => {
    const selection = document.getSelection();
    const selectedText = selection?.toString();
    const { pageX } = e;
    const { pageY } = e;
    if (selectedText?.trim() !== "") {
      setTop(pageY - 60);
      setLeft(pageX - 55);
      setShowToolbar(true);
      updateTempObj.current = selection?.getRangeAt(0);
    } else {
      setShowToolbar(false);
    }
  };

  useEffect(() => {
    const dataHolder = document.getElementById("desc");
    dataHolder?.addEventListener("focus", handlePlaceholder);
    dataHolder?.addEventListener("mouseup", handleTextSelection);
    return () => {
      dataHolder?.removeEventListener("mouseup", handleTextSelection);
      dataHolder?.removeEventListener("focus", handlePlaceholder);
    };
  }, []);
  useEffect(() => {
    const target = document.getElementById("desc");
    if (target) {
      target.addEventListener("paste", (event) => {
        event.preventDefault();
        const paste = event.clipboardData?.getData("text") || "";
        const selection = window.getSelection();
        if (!selection?.rangeCount) return;
        selection.deleteFromDocument();
        selection
          .getRangeAt(0)
          .insertNode(document.createTextNode(paste.replace(/(\r\n|\n|\r)/gm, "")));
      });
    }
  });
  const onUpdate = () => {
    const dataHolder = document?.getElementById("desc");
    let tempData = "";
    if (dataHolder) {
      tempData = dataHolder.innerHTML;
    }
    setData(tempData);
  };
  const onOrderedListClick = (e) => {
    document.execCommand("insertOrderedList", false, undefined);
    setShowToolbar(false);
  };
  const onUnOrderedListClick = (e) => {
    document.execCommand("insertUnorderedList", false, undefined);
    setShowToolbar(false);
  };
  const onBoldClick = (e) => {
    document.execCommand("bold", false, undefined);
    setShowToolbar(false);
  };
  const onItalicClick = (e) => {
    document.execCommand("italic", false, undefined);
    setShowToolbar(false);
  };
  const onUnderlineClick = (e) => {
    document.execCommand("underline", false, undefined);
    setShowToolbar(false);
  };
  const onBlockquoteClick = () => {
    if (document.queryCommandValue("FormatBlock") === "blockquote") {
      document.execCommand("FormatBlock", false, "<div>");
    } else {
      document.execCommand("FormatBlock", false, "<blockquote>");
    }
    setShowToolbar(false);
  };
  const onClickDone = (url) => {
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(updateTempObj.current);
    if (url) {
      document.execCommand(
        "insertHTML",
        false,
        `<a href="${url}" target="_blank">${selection}</a>`,
      );
    }
    setUrlDialog(false);
  };
  const onUrlDialogOpen = () => {
    if (ifLink) {
      document.execCommand("unLink", false, `${updateTempObj.current}`);
      setShowToolbar(false);
    } else {
      setUrlDialog(true);
      setShowToolbar(false);
    }
  };
  const onClickClose = () => {
    setUrlDialog(false);
  };
  const onChange = (e) => {
    setCheckDesc(document?.getElementById("desc")?.innerHTML);
  };
  const handleDescriptionChange = (e) => {
    setShowOutput(document?.getElementById("desc")?.innerHTML);
    if (!showToolbar) {
      setState({
        ...state,
        CommonFields: {
          ...state.CommonFields,
          description: document?.getElementById("desc")?.innerHTML,
          settings: {
            ...state.CommonFields.settings,
            socialog_description: trimString(
              handleHtmlTags(document?.getElementById("desc")?.innerHTML),
              200,
            ),
          },
          structure_data: "",
        },
      });
      onUpdate();
    }
  };
  const showMediaCheck = (e) => {
    if (showMediaOption && currentLine.current !== "") {
      // setShowMediaOption(false);
    }
  };
  function whichTag(tagName) {
    let sel, containerNode;
    let tagFound = false;
    // eslint-disable-next-line no-param-reassign
    tagName = tagName.toUpperCase();
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount > 0) {
        containerNode = sel.getRangeAt(0).commonAncestorContainer;
      }
    } else if (sel === document.getSelection && sel.type !== "Control") {
      containerNode = sel.createRange().parentElement();
    }
    while (containerNode) {
      if (containerNode.nodeType === 1 && containerNode.tagName === tagName) {
        tagFound = true;
        containerNode = null;
      } else {
        containerNode = containerNode.parentNode;
      }
    }
    return tagFound;
  }

  const printInput = (e) => {
    setShowToolbar(false);
    if (e.key === "Backspace" || e.key === "Delete") {
      currentLine.current = currentLine.current.slice(0, currentLine.current.length - 1);
      if (currentLine.current === "") setShowMediaOption(true);
      return;
    }
    if (e.key === "Enter") {
      setShowMediaOption(true);
      if (whichTag("blockquote")) {
        document.execCommand("Outdent");
      }
      if (whichTag("b")) {
        document.execCommand("bold", false, undefined);
      }
      if (whichTag("i")) {
        document.execCommand("italic", false, undefined);
      }
      if (whichTag("u")) {
        document.execCommand("underline", false, undefined);
      }
      if (desc.current === "") {
        desc.current = `<p>${currentLine.current}</p>`;
      } else {
        desc.current = `${desc.current}<br><p>${currentLine.current}</p>`;
      }
      currentLine.current = "";
    } else {
      currentLine.current = currentLine.current + e.key;
    }
  };

  const handleSelectedImage = async (image) => {
    const payload = {
      bitstreamId: image.bitStreamId,
      visibility: "public",
    };
    setGalleryState(false);
    setLoader(true);
    setSelectedImage(image);
    const data = await cropRequest(CROP_TYPE.NOCROP, payload, () => {}, image);
    const imgUrl: any =
      relativeImageURL(`${data?.original_image_relative_path}.${data?.ext}`) || "";
    const getImageUrl = imgUrl?.substring(0, imgUrl?.lastIndexOf("."));
    const getExtensions = imgUrl?.split(".")?.pop();

    const pictureElement = `<picture>
    <source 
    type='image/webp'
    srcset=${getImageUrl + ".webp"}
    >
    <source
    srcset=${getImageUrl}.${getExtensions}>
    <img
    loading='lazy' alt='cropped-img' src=${getImageUrl + ".webp"} class='descAsset' style='display:block;object-fit:cover'></picture>
    `;
    const el = document.getElementById("desc");
    if (el !== null) {
      el.innerHTML = `${el.innerHTML}<div style='display: flex;justify-content: center'>${pictureElement}
      </div>
      <br>`;
    }
    desc.current = `${desc.current}<div style='display: flex;justify-content: center'>${pictureElement}</div><br>`;
    setShowOutput(document?.getElementById("desc")?.innerHTML);
    setState({
      ...state,
      CommonFields: {
        ...state.CommonFields,
        description: document?.getElementById("desc")?.innerHTML,
        settings: {
          ...state.CommonFields.settings,
          socialog_description: trimString(
            handleHtmlTags(document?.getElementById("desc")?.innerHTML),
            200,
          ),
        },
      },
    });
    setLoader(false);
  };

  const handleSelectedVideo = (video) => {
    setSelectedVideo(video);
    const el = document.getElementById("desc");
    if (el !== null) {
      el.innerHTML = `${el.innerHTML}<div style='display: flex;justify-content: center'><video class='descAsset' controls playsinline style="object-fit: cover; display: block" poster='${relativeImageURL(video.Thumbnail)}'><source src='${video.Url}' type="video/mp4"></video></div><br>`;
    }
    desc.current = `${desc.current}<div style='display: flex;justify-content: center'><video class='descAsset' controls playsinline style="object-fit: cover; display: block" poster='${relativeImageURL(video.Thumbnail)}'><source src='${video.Url}' type="video/mp4"></video></div><br>`;
    setShowOutput(document?.getElementById("desc")?.innerHTML);
    setState({
      ...state,
      CommonFields: {
        ...state.CommonFields,
        description: document?.getElementById("desc")?.innerHTML,
        settings: {
          ...state.CommonFields.settings,
          socialog_description: trimString(
            handleHtmlTags(document?.getElementById("desc")?.innerHTML),
            200,
          ),
        },
      },
    });
    setGalleryState(false);
  };

  const handleSelectedContent = (item) => {
    const el = document.getElementById("desc");
    const contentAdded = ReactDomServer.renderToString(
      <DescriptionContentCard content={item}></DescriptionContentCard>,
    );
    if (el !== null) {
      el.innerHTML = el.innerHTML + contentAdded;
    }
    desc.current = desc.current + contentAdded;
    setShowOutput(document?.getElementById("desc")?.innerHTML);
    setContentGalleryState(!contentGalleryState);
    setState({
      ...state,
      CommonFields: {
        ...state.CommonFields,
        description: document?.getElementById("desc")?.innerHTML,
        settings: {
          ...state.CommonFields.settings,
          socialog_description: trimString(
            handleHtmlTags(document?.getElementById("desc")?.innerHTML),
            200,
          ),
        },
      },
    });
  };

  const onToggleContentGallery = () => {
    setContentGalleryState(!contentGalleryState);
  };

  const setImageOrVideoToDefault = () => {
    setSelectedImage({
      Title: "",
      Thumbnail: "",
      Description: "",
    });
    setSelectedVideo({
      Title: "",
      Thumbnail: "",
      Description: "",
      Url: "",
    });
  };

  const toggleGallery = (toggleState, type) => {
    setGalleryState(toggleState);
    if (type === "cancel") {
      setImageOrVideoToDefault();
    }
  };

  const showGallery = (gType) => {
    // Function to rearrange contentTypes array based on gType
    const rearrangeContentTypes = (type) => {
      return [type, ...contentTypes.filter((item) => item !== type)];
    };
    if (gType === "content") {
      contentType.current = contentTypes;
      setContentGalleryState(true);
    } else if (contentTypes.includes(gType)) {
      contentType.current = rearrangeContentTypes(gType);
      setContentGalleryState(true);
    } else {
      galleryType.current = gType;
      setGalleryState(true);
    }
  };

  const blockQuotes = `
      blockquote{ 
        margin: 0;
        border-left: 3px solid black;
        padding: 5px 10px;
        font-style:italic;
      }
      .placeholdertext[placeholder]:empty::before {
        content: attr(placeholder);
        color: #ced3d9; 
        font-size: 18px;
        line-height: 1.8;
    }
    
    .placeholdertext[placeholder]:empty:focus::before {
        content: "";
    }
      `;
  const LanguageNameMapping = {
    en: "English",
    fr: "French",
    de: "German",
  };

  const chatGptResponse = (prompt) => {
    const requestParam = {
      input: {
        prompt: prompt,
      },
    };
    createChatGptRequest(requestParam)
      .then((result: any) => {
        const chatGptDataResponse = result?.authoring_getContentOpenai?.text;
        const dataAdded = chatGptDataResponse.replace(/(\r\n|\n|\r)/gm, "<br>");
        if (chatGptDataResponse) {
          setIsLoading(false);
          const el = document.getElementById("desc");
          if (el !== null) {
            el.innerHTML = el.innerHTML + dataAdded;
          }
          desc.current = desc.current + dataAdded;
          setState({
            ...state,
            CommonFields: {
              ...state.CommonFields,
              description: document?.getElementById("desc")?.innerHTML,
              settings: {
                ...state.CommonFields.settings,
                socialog_description: trimString(handleHtmlTags(chatGptDataResponse), 200),
              },
            },
          });
          setDescription(document?.getElementById("desc")?.innerHTML);
          setCheckDesc(document?.getElementById("desc")?.innerHTML);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:chatGptResponse`, err);
        throw err;
      });
  };

  const chatGPT = () => {
    setIsLoading(true);
    const chatGptPrompt = "Please write 2 paragraph, max 100 words article about";

    chatGptResponse(`${chatGptPrompt} ${title} in ${LanguageNameMapping["en"]}`);
  };

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      setDescription(state?.CommonFields?.description);
    }
  }, [state]);

  return (
    <>
      {isLoading && <ChatGptLoader />}
      {loader && <Loader />}
      <Box
        sx={{
          backgroundColor: "#FFF",
        }}>
        <style>{blockQuotes}</style>
        {galleryState && (
          <DamContentGallery
            handleImageSelected={handleSelectedImage}
            handleSelectedVideo={handleSelectedVideo}
            toggleGallery={toggleGallery}
            dialogOpen={galleryState}
            isCrop={false}
            assetType={galleryType.current === "Images" ? "Image" : "Video"}
          />
        )}
        <Dialog fullScreen open={contentGalleryState}>
          <ContentGallery
            handleSelectedContent={handleSelectedContent}
            onToggleContentGallery={onToggleContentGallery}
            contentType={contentType.current}
          />
        </Dialog>
      </Box>
      <Box
        sx={{
          display: "flex",
          fontSize: "16px",
          backgroundColor: "white",
          width: "100%",
          position: "relative",
        }}>
        <Box
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            left: { xs: "28px", md: "-68px" },
          }}>
          {showMediaOption && <MediaTray showGallery={showGallery} />}
        </Box>
        <Box
          id='desc'
          sx={{
            width: { xs: "100%", md: "93%", em: "95%" },
          }}
          contentEditable
          onKeyUp={(e) => printInput(e)}
          onKeyDown={(e) => showMediaCheck(e)}
          onBlur={handleDescriptionChange}
          onInput={(e) => onChange(e)}
          onPaste={(e) => onChange(e)}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description, { ADD_ATTR: ["target"] } || ""),
          }}
          className='placeholdertext'></Box>

        <ButtonGroup
          className='tools'
          id='toolbar'
          style={{
            display: showToolbar ? "inline-flex" : "none",
            backgroundColor: "black",
            left,
            top,
            position: "fixed",
          }}>
          <IconButton
            onClick={onOrderedListClick}
            data-testid='article-orderedList-icon'
            sx={{ color: "white" }}>
            <FormatListNumberedIcon />
          </IconButton>
          <IconButton
            onClick={onUnOrderedListClick}
            data-testid='article-unOrderedList-icon'
            sx={{ color: "white" }}>
            <FormatListBulletedIcon />
          </IconButton>
          <IconButton onClick={onBoldClick} data-testid='article-bold-icon' sx={{ color: "white" }}>
            <FormatBoldIcon />
          </IconButton>
          <IconButton
            onClick={onItalicClick}
            data-testid='article-italic-icon'
            sx={{ color: "white" }}>
            <FormatItalicIcon />
          </IconButton>
          <IconButton
            onClick={onUnderlineClick}
            data-testid='article-underline-icon'
            sx={{ color: "white" }}>
            <FormatUnderlinedIcon />
          </IconButton>
          <IconButton
            onClick={onUrlDialogOpen}
            data-testid='article-open-url-dialog'
            sx={{ color: "white" }}>
            <LinkIcon />
          </IconButton>
          <IconButton
            onClick={onBlockquoteClick}
            data-testid='article-block-quote'
            sx={{ color: "white" }}>
            <FormatQuoteIcon />
          </IconButton>
        </ButtonGroup>
        <Box
          sx={{
            height: "30px",
            width: "30px",
            cursor: title?.length > 0 ? "pointer" : "initial",
            alignSelf: "flex-end",
            position: "absolute",
            right: { xs: "21px", md: "0px" },
            bottom: { xs: "-30px", md: "0px" },
          }}
          onClick={() => title?.length > 0 && chatGPT()}
          data-testid='article-chatgpt'>
          <Tooltip
            className='divTooltip'
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  background: title?.length > 0 ? "#D7ECFD" : "#D9DBE9",
                  color: title?.length > 0 ? "#14142B" : "#6E7191",
                  fontSize: "12px",
                  minHeight: "30px",
                  lineHeight: "30px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  marginRight: "10px",
                  maxWidth: "800px", // Adjust width as needed to fit the title in one line
                  whiteSpace: "nowrap", // Ensure the title doesn't wrap to the next line
                },
              },
            }}
            title='Write your content with AI'>
            {title?.length > 0 ? (
              <img src={AiSvg} className='DivEnable' height='30px' width='30' alt='' />
            ) : (
              <img src={AiSvgDisabled} className='DivDisable' height='30px' width='30' alt='' />
            )}
          </Tooltip>
        </Box>
      </Box>
      {isUrlDialog ? (
        <AddUrlDialog
          titledata=''
          isDialogOpen={isUrlDialog}
          closeButtonHandle={onClickClose}
          data-testid='article-url-dialog-close'
          doneButtonHandle={onClickDone}
        />
      ) : null}
    </>
  );
}
export default React.memo(Description);
