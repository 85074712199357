import { Editor } from "@platformx/utilities";
import AddCollection from "./common/AddCollection/AddCollection";
import AddContent from "./common/AddContent/AddContent";
import { AutoCompleteDropDown } from "./common/AutoCompleteDropDown/AutoCompleteDropDown";
import CheckBoxComponent from "./common/CheckBox/CheckBoxComponent";
import DateAndTimePicker from "./common/DateAndTimePicker/DateAndTimePicker";
import DatePickerComponent from "./common/DatePicker/DatePicker";
import { Dropdown } from "./common/DropDown/Dropdown";
import ImagePicker from "./common/ImagePicker/ImagePicker";
import AddOptions from "./common/ImageWithOptions/AddOptions";
import Query from "./common/Query/Query";
import RadioGroupButtons from "./common/RadioGroup/RadioGroupButtons";
import TagListingComponent from "./common/TagListing/TagListingComponent";
import TextBox from "./common/TextBox/TextBox";
import Toggle from "./common/Toggle/Toggle";
import Custom1 from "./customComponents/Custom1/Custom1";
import Dynamic from "./customComponents/Dynamic/Dynamic";

const handleChange = (event, type, clearErrors, setState, state) => {
  clearErrors(event.target.name);
  if (type === "number") {
    setState({
      ...state,
      [event.target.name]: event.target.value.replace(/[^0-9]/g, ""),
    });
  } else {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  }
};
const handleToggleChange = (event, setState, state) => {
  //For Boolean type components
  setState({
    ...state,
    [event.target.name]: !state[event.target.name],
  });
};
const handleOnBlur = (event, type, setState, state) => {
  if (type === "number") {
    setState({
      ...state,
      [event.target.name]: event.target.value.replace(/[^0-9]/g, ""),
    });
  } else {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  }
};
const updateField = (updatedPartialObj, name) => (clearErrors, setState, state) => {
  //For ImagePicker Component
  clearErrors(name);
  setState({
    ...state,
    [name]: {
      ...state[name],
      original_image: updatedPartialObj?.original_image,
      published_images: updatedPartialObj?.published_images,
    },
  });
};
const handleChangeOn = (newValue, field, multiple) => (clearErrors, setState, state, fields) => {
  clearErrors(field?.name);
  const subTypeName = fields?.filter((value) => {
    return value.dependent_name === field?.name;
  });
  if (subTypeName.length > 0) {
    setState({
      ...state,
      [field.name]: multiple ? newValue : newValue !== null ? [newValue] : [],
      [subTypeName[0]?.name]: [],
    });
  } else {
    setState({
      ...state,
      [field.name]: multiple ? newValue : [newValue],
    });
  }
};
const updateState = (name, value) => (clearErrors, setState, state) => {
  //For TagListing Component
  clearErrors(name);
  setState({
    ...state,
    [name]: value,
  });
};

const optionCallBack = (field, state) => {
  //For Dynamic Dropdown
  if (state?.[field?.dependent_name]) {
    return field?.options?.filter((item) =>
      state?.[field?.dependent_name]?.map((x) => x.id).includes(item.parentId),
    );
  } else {
    return field?.options;
  }
};
function builder(
  field,
  state,
  errors,
  register,
  clearErrors,
  setState,
  fields,
  isEdit,
  title,
  control,
  callbackEvents,
  setError,
  groupedFields = {},
  setGroupedFields = {},
) {
  switch (field?.type) {
    case "text":
      switch (field.variant) {
        case "multiline":
          return (
            // <TextArea
            //   field={field}
            //   errors={errors}
            //   register={register}
            //   clearErrors={clearErrors}
            //   data={state[field?.name]}
            //   handleChange={(event) =>
            //     handleChange(event, field?.type, clearErrors, setState, state)
            //   }
            //   handleOnBlur={(event) => handleOnBlur(event, field?.type, setState, state)}
            // />
            <Editor
              errors={errors}
              field={field}
              handleChange={(event) =>
                handleChange(event, field?.type, clearErrors, setState, state)
              }
              description={state[field?.name]}
              control={control}
              state={state}
            />
          );
        case "number":
          return (
            <TextBox
              type='number'
              field={field}
              errors={errors}
              register={register}
              clearErrors={clearErrors}
              data={state[field?.name]}
              handleChange={(event) =>
                handleChange(event, field?.type, clearErrors, setState, state)
              }
              handleOnBlur={(event) => handleOnBlur(event, field?.type, setState, state)}></TextBox>
          );
        default:
          return (
            <TextBox
              type='text'
              field={field}
              errors={errors}
              register={register}
              clearErrors={clearErrors}
              data={state[field?.name]}
              handleChange={(event) =>
                handleChange(event, field?.type, clearErrors, setState, state)
              }
              handleOnBlur={(event) => handleOnBlur(event, field?.type, setState, state)}></TextBox>
          );
      }
    case "image":
      return (
        <ImagePicker
          field={field}
          errors={errors}
          register={register}
          state={state}
          updateField={(updatedPartialObj, name) =>
            updateField(updatedPartialObj, name)(clearErrors, setState, state)
          }></ImagePicker>
      );
    case "content":
      switch (field?.variant) {
        case "contentType":
          return (
            <AddContent
              state={state}
              setState={setState}
              field={field}
              error={errors[field.name]}
              register={register}
              clearErrors={clearErrors}
            />
          );

        case "collection":
          return (
            <AddCollection
              state={state}
              setState={setState}
              field={field}
              error={errors[field.name]}
              register={register}
              clearErrors={clearErrors}
            />
          );
        default:
          return null;
      }

    // return (
    //   <ContentTypePicker
    //     field={field}
    //     errors={errors}
    //     register={register}
    //     state={state}
    //     updateField={() => {}}
    //   />
    // );
    case "query":
      return (
        <Query
          field={field}
          state={state}
          errors={errors}
          setState={setState}
          register={register}
          isEdit={isEdit}
          clearErrors={clearErrors}
        />
      );
    case "imagewithtext":
      return <AddOptions setState={setState} state={state} field={field} />;
    case "date":
      return (
        <DatePickerComponent
          disablePast={field?.disable_past}
          state={state}
          setState={setState}
          field={field}
          clearErrors={clearErrors}
          error={errors[field.name]}
          control={control}
          isEdit={isEdit}
        />
      );
    case "radio":
      return (
        <RadioGroupButtons
          value={field?.options}
          state={state}
          field={field}
          row={true}
          handleChange={(event) => handleChange(event, field?.type, clearErrors, setState, state)}
          error={errors[field.name]}
          register={register}
        />
      );
    case "dropdown":
      return field?.variant === "searchdropdown" ? (
        <AutoCompleteDropDown
          options={field?.dependent_name ? optionCallBack(field, state) : field?.options}
          state={state}
          field={field}
          optionFormat='label'
          error={errors[field.name]}
          register={register}
          handleChangeOn={(newValue, currentField, multiple) =>
            handleChangeOn(newValue, currentField, multiple)(clearErrors, setState, state, fields)
          }
          groupedFields={groupedFields}
          setGroupedFields={setGroupedFields}
        />
      ) : (
        <Dropdown
          options={field?.options}
          placeholder={field?.placeholder}
          setState={setState}
          state={state}
          error={errors[field.name]}
          register={register}
          field={field}
          clearErrors={clearErrors}
          callbackEvents={callbackEvents}
        />
      );
    // case "checkbox":
    //   return (
    //     <CheckBoxComponent
    //       field={field}
    //       error={errors[field.name]}
    //       register={register}
    //       handleChange={handleToggleChange}
    //       checked={state[field?.name]}
    //     />
    //   );
    case "boolean":
      switch (field.variant) {
        case "toggle":
          return (
            <Toggle
              field={field}
              error={errors[field.name]}
              disabled={false}
              color={"#000000"}
              onChange={(event) => handleToggleChange(event, setState, state)}
              checked={state[field?.name]}
              name={field?.name}
              register={register}
            />
          );
        case "checkbox":
          return (
            <CheckBoxComponent
              field={field}
              error={errors[field.name]}
              register={register}
              handleChange={(event) => handleToggleChange(event, setState, state)}
              checked={state[field?.name]}
            />
          );
        default:
          return null;
      }
    case "tag":
      return (
        Object.keys(state).length > 0 && (
          <TagListingComponent
            field={field}
            isEdit={isEdit}
            selectedTag={state[field?.name]}
            subCategory={title}
            updateState={(name, value) => updateState(name, value)(clearErrors, setState, state)}
            register={register}
            error={errors[field.name]}
          />
        )
      );
    case "datetime":
      return (
        <DateAndTimePicker
          disablePast={field?.disable_past}
          field={field}
          error={errors[field.name]}
          state={state}
          updateState={(name, value) => updateState(name, value)(clearErrors, setState, state)}
          clearErrors={clearErrors}
          control={control}
          isEdit={isEdit}
        />
      );
    case "custom1": //Challenge Rule // This is a sample component developed for healthcare.
      return (
        <Custom1
          type='text'
          field={field}
          errors={errors}
          register={register}
          clearErrors={clearErrors}
          data={state[field?.name]}
          handleChange={(event) => handleChange(event, field?.type, clearErrors, setState, state)}
          handleOnBlur={(event) => handleOnBlur(event, field?.type, setState, state)}
        />
      );
    case "dynamic":
      return (
        <Dynamic
          type='number'
          field={field}
          errors={errors}
          register={register}
          clearErrors={clearErrors}
          state={state}
          setState={setState}
          setErrors={setError}
          isEdit={isEdit}
        />
      );

    default:
      return null;
  }
}

export default builder;
